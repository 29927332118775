import { useQuery } from 'react-query';

import api from 'services/api.service';

export function useMenuItemsList(email) {
  const itemsPerPage = 50;
  const url = `/v2/mxright/menu/${email}`;

  const getMenuItemsList = () => api.get(url, {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get(url, {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxright.menu', { email }], getMenuItemsList, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useMetersList(email) {
  const itemsPerPage = 50;
  const url = `/v2/mxright/meters/${email}`;

  const getMetersList = () => api.get(url, {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get(url, {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxright.meters', { email }], getMetersList, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useCommonData(email) {
  const getCommonData = () => api.get(`/v2/mxright/common-data/${email}`);

  return useQuery(['mxright.commonData', { email }], getCommonData, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useReportItemsList(email, options) {
  const itemsPerPage = 50;
  const url = `/v2/mxright/items/${email}`;

  const getReportItemsList = () => api.get(url, {
    page: 1,
    itemsPerPage,
  }).then(async (data) => {
    const otherPageNumbers = Array.from({
      length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
    }, (_, i) => i + 2);

    const otherData = await Promise.all(otherPageNumbers
      .map(async (page) => api.get(url, {
        page,
        itemsPerPage,
      })));

    return [
      ...data['hydra:member'],
      ...otherData.map((check) => check['hydra:member']).flat(1),
    ];
  });

  return useQuery(['mxright.items', { email }], getReportItemsList, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
    ...options,
  });
}

export function useGroups() {
  const getGroups = () => api.get('/v2/mxright/groups');

  return useQuery(['monitor.groups'], getGroups);
}
