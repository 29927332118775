import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useGenerateEmail() {
  const generateEmail = () => api.get('/v2/generate-email').then((res) => res.email);

  return useQuery(['email.generate'], generateEmail);
}

export function useSendReport() {
  const sendReport = ({ toEmail, recipient, message }) => api.post('/v2/send-report', { toEmail, recipient, message });

  return useMutation(sendReport);
}

export function useSendSharedReport() {
  const sendSharedReport = ({
    recipient, url, subject, message, resource, linkText, // resource = IRI
  }) => api.post('/v2/send-shared-report', {
    recipient, url, subject, message, resource, linkText,
  });

  return useMutation(sendSharedReport);
}

export function useEmailList({ fromEmail, page, perPage, getAll, emailType } = {}) {
  const itemsPerPage = 50;

  const getEmailList = () => api.get('/v2/emails', {
    page,
    itemsPerPage: perPage,
    'order[insertTimestamp]': 'desc',
    fromEmail,
    emailType,
  })
    .then((data) => ({
      items: data['hydra:member'],
      totalItems: data['hydra:totalItems'],
    }));

  const getAllEmailList = () => api.get('/v2/emails', {
    page: 1,
    itemsPerPage,
    'order[insertTimestamp]': 'desc',
    fromEmail,
    emailType,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map((pageNumber) => api.get('/v2/emails', {
          page: pageNumber,
          itemsPerPage,
          'order[insertTimestamp]': 'desc',
          fromEmail,
          emailType,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['email.list', fromEmail, page, perPage, getAll, emailType], getAll
    ? getAllEmailList
    : getEmailList);
}

export function useShareReport() {
  const shareReport = ({ email, shared }) => api.patch(`/v2/share-report/${email}`, {
    shared,
  });

  return useMutation(shareReport);
}

export function useDeleteEmailCheck() {
  const deleteEmailCheck = (id) => api.delete(`/v2/emails/${id}`);

  return useMutation(deleteEmailCheck, {
    onSuccess: () => {
      queryClient.invalidateQueries('email.list');
    },
  });
}
