import { useCallback } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { AuthLogoIcon, SmallClockIcon } from 'assets/icons';

import { routes } from 'routes';

import { mxrightApi } from 'resources/mxright';

import ScoreCircle from 'components/ScoreCircle';
import Speedometer from 'components/Speedometer';

import './styles.scss';

import Button from 'components/Button';

const ResultsFrame = () => {
  const params = useParams();

  const { data: commonReportData, isLoading: isCommonDataLoading } = mxrightApi.useCommonData(params.email);
  const { data: meters, isLoading: isMetersLoading } = mxrightApi.useMetersList(params.email);

  const onNavigateToFullReport = useCallback(() => {
    window.open(routes.result.path.replace(':email', params.email), '_blank');
  }, [params.email]);

  if (isCommonDataLoading || isMetersLoading) {
    return null;
  }

  return (
    <div className="results-frame-wrapper">
      <div className="header">
        <AuthLogoIcon />

        <div className="circle">
          <ScoreCircle
            mxrScoreTotal={commonReportData ? 100 : undefined}
            mxrScoreTotalPass={commonReportData?.totalScore}
            ratingColor={commonReportData?.rating?.color}
            ratingLabel={commonReportData?.rating?.label}
          />
        </div>

        <div className={cn('email-for-container')}>
          {commonReportData?.fromEmail && (
          <p>
            Test for
            {' '}
            {commonReportData.fromEmail}
          </p>
          )}

          {commonReportData?.resultAt && (
          <div className="result-at">
            <SmallClockIcon />

            <p>{new Date(commonReportData.resultAt).toLocaleString(navigator.language)}</p>
          </div>
          )}
        </div>
      </div>

      <div className="report-wrapper">
        <div className="speedometer-container">
          {meters.map((item) => (
            <Speedometer
              key={item.id}
              serviceGroup={item.name}
              passTestsCount={item.numPassed}
              totalTestsCount={item.numTotal}
            />
          ))}
        </div>

        <Button className="report-button" onClick={onNavigateToFullReport}>See Full Report</Button>
      </div>
    </div>
  );
};

export default ResultsFrame;
