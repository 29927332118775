import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Doughnut } from 'react-chartjs-2';

import { OpenedEyeIcon, TrashIcon } from 'assets/icons';

import Modal from 'components/Modal';
import ScoreCircle from 'components/ScoreCircle';
import Pagination from 'components/Pagination';

import IconButton from '@mui/material/IconButton';

import { PLACEMENT_TEST_STATISTIC } from 'pages/DeliverabilityTest/components/TestCard';

import './styles.scss';

const ReportHistoryModal = ({
  open,
  onClose,
  forName,
  items,
  page,
  perPage,
  totalItems,
  onPageChanged,
  onItemClick,
  showEmail,
  onDelete,
  isPlacementTest,
}) => {
  const onDeleteClick = useCallback((e, item) => {
    e.stopPropagation();

    onDelete(item);
  }, [onDelete]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Report History for ${forName}`}
    >
      <div className="report-history-modal-wrapper">
        <div className="line" />

        <div
          className={cn('items-wrapper', 'scroll')}
        >
          {items.map((item) => {
            const runStats = PLACEMENT_TEST_STATISTIC.map((stat) => ({
              ...stat,
              percent: Math.round((item[stat.id] / item.total) * 100),
            }))
            // remove 'Other' if it's 0
              .filter((stat) => stat.id !== 'other' || stat.percent > 0);

            return (
              <div key={item.value} className="item" onClick={() => onItemClick(item)} aria-hidden>
                <div>
                  <div className="circle-wrapper">
                    {isPlacementTest
                      ? (
                        <div style={{ width: 30, height: 30 }}>
                          <Doughnut
                            data={{
                              datasets: [{
                                backgroundColor: runStats.map((stat) => stat.color),
                                pointRadius: 0,
                                pointHitRadius: 100,
                                borderWidth: 0,
                                data: runStats.map((stat) => stat.percent),
                              }],
                            }}
                            options={{
                              cutoutPercentage: 75,
                              spanGaps: false,
                              legend: {
                                display: false,
                              },
                              maintainAspectRatio: false,
                              tooltips: { enabled: false },
                            }}
                          />
                        </div>
                      ) : (
                        <ScoreCircle
                          mxrScoreTotal={10}
                          mxrScoreTotalPass={item.mxrScore || item.score}
                          ratingColor={item.ratingColor}
                          ratingLabel={item.ratingLabel}
                          onlyCircle
                          size="small"
                        />
                      )}

                    {showEmail && item.fromEmail
                      ? (
                        <div className="label-container">
                          <p>{item.fromEmail}</p>
                          <p>{item.label}</p>
                        </div>
                      )
                      : <p className="label">{item.label}</p>}
                  </div>

                  <div className="icons">
                    <OpenedEyeIcon />

                    {onDelete && (
                    <IconButton onClick={(e) => onDeleteClick(e, item)}>
                      <TrashIcon />
                    </IconButton>
                    )}
                  </div>
                </div>

                <div className="line" />
              </div>
            );
          })}
        </div>

        <Pagination
          activePage={page}
          perPage={perPage}
          totalItems={totalItems}
          onPageChanged={onPageChanged}
          className="report-hisotry-modal-pagination"
        />
      </div>
    </Modal>
  );
};

ReportHistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  forName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  showEmail: PropTypes.bool,
  onDelete: PropTypes.func,
  isPlacementTest: PropTypes.bool,
};

ReportHistoryModal.defaultProps = {
  showEmail: false,
  onDelete: null,
  isPlacementTest: false,
};

export default React.memo(ReportHistoryModal);
