import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { connectivityApi } from 'resources/connectivity';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import { Alert, AlertTitle } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import CircularProgress from '@mui/material/CircularProgress';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const PORT_TYPE_SELECT_ITEMS = [
  { label: 'SSL', value: 'ssl' },
  { label: 'TLS', value: 'tls' },
  { label: 'None', value: 'none' },
];

const AddIMAPModal = ({
  open,
  onClose,
  onAdded,
}) => {
  const [isShowDetails, setIsShowDetails] = useState(false);

  const [fromName, setFromName] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [serverData, setServerData] = useState({
    imap: {
      server: '',
      port: '', // numbers
      type: PORT_TYPE_SELECT_ITEMS[0].value, // ssl | tls
      username: '',
      password: '',
    },
    smtp: {
      server: '',
      port: '', // numbers
      type: PORT_TYPE_SELECT_ITEMS[0].value, // ssl | tls
      username: '',
      password: '',
    },
  });

  const { mutate: sendAutoDiscover, isLoading: isAutoDiscoverLoading } = connectivityApi.useAutoDiscover();
  const { mutate: createIMAPAccount, isLoading: isCreateIMAPAccountLoading } = connectivityApi.useCreateIMAPAccount();

  const isEmailForGroupLoading = false;

  const [errors, setErrors] = useState({
    fromName: null,
    fromEmail: null,
    password: null,
    confirmPassword: null,
    imap: {
      server: null,
      port: null,
      type: null,
      username: null,
      password: null,
    },
    smtp: {
      server: null,
      port: null,
      type: null,
      username: null,
      password: null,
    },
  });
  const [generalErrors, setGeneralErrors] = useState({
    imap: {
      title: 'IMAP Connection Failed',
      description: null,
      expanded: false,
    },
    smtp: {
      title: 'SMTP Connection Failed',
      description: null,
      expanded: false,
    },
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!fromName) {
      newErrors.fromName = 'This field is required';
    }

    if (!fromEmail) {
      newErrors.fromEmail = 'This field is required';
    }

    if (fromEmail && !validateEmail(fromEmail)) {
      newErrors.fromEmail = 'Incorrect email format';
    }

    if (!isShowDetails) {
      if (!password) {
        newErrors.password = 'This field is required';
      }

      if (password !== confirmPassword) {
        newErrors.confirmPassword = 'The passwords don\'t match';
      }
    } else {
      if (!serverData.imap.server) {
        newErrors.imap = {
          ...newErrors.imap,
          server: 'This field is required',
        };
      }

      if (!serverData.imap.port) {
        newErrors.imap = {
          ...newErrors.imap,
          port: 'This field is required',
        };
      }

      if (serverData.imap.port && Number.isNaN(Number(serverData.imap.port))) {
        newErrors.imap = {
          ...newErrors.imap,
          port: 'This field should be a number',
        };
      }

      if (!serverData.imap.username) {
        newErrors.imap = {
          ...newErrors.imap,
          username: 'This field is required',
        };
      }

      if (!serverData.imap.password) {
        newErrors.imap = {
          ...newErrors.imap,
          password: 'This field is required',
        };
      }

      if (!serverData.smtp.server) {
        newErrors.smtp = {
          ...newErrors.smtp,
          server: 'This field is required',
        };
      }

      if (!serverData.smtp.port) {
        newErrors.smtp = {
          ...newErrors.smtp,
          port: 'This field is required',
        };
      }

      if (serverData.smtp.port && Number.isNaN(Number(serverData.smtp.port))) {
        newErrors.smtp = {
          ...newErrors.smtp,
          port: 'This field should be a number',
        };
      }

      if (!serverData.smtp.username) {
        newErrors.smtp = {
          ...newErrors.smtp,
          username: 'This field is required',
        };
      }

      if (!serverData.smtp.password) {
        newErrors.smtp = {
          ...newErrors.smtp,
          password: 'This field is required',
        };
      }
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [fromName, fromEmail, serverData, isShowDetails, password, confirmPassword]);

  const onSaveClick = useCallback((defaultData) => {
    if (!validateForm()) {
      return;
    }

    setGeneralErrors((oldGeneralErrors) => ({
      ...oldGeneralErrors,
      imap: {
        ...oldGeneralErrors.imap,
        description: null,
      },
      smtp: {
        ...oldGeneralErrors.smtp,
        description: null,
      },
    }));

    createIMAPAccount(defaultData || {
      fromName,
      fromEmail,
      username: serverData.imap.username,
      password: serverData.imap.password,
      host: serverData.imap.server,
      port: serverData.imap.port,
      secureLevel: serverData.imap.type,
      smtpUsername: serverData.smtp.username,
      smtpPassword: serverData.smtp.password,
      smtpHost: serverData.smtp.server,
      smtpPort: serverData.smtp.port,
      smtpSecureLevel: serverData.smtp.type,
    }, {
      onSuccess: (data) => {
        uiNotificationService.showSuccess('Email account has been created');

        onAdded(data);

        onClose();
      },
      onError: (error) => {
        setIsShowDetails(true);

        if (error.data.violations?.length) {
          const filedsErrors = {};

          error.data.violations.forEach((violation) => {
            filedsErrors[violation.propertyPath] = violation.message;
          });

          setErrors((oldErrors) => ({
            ...oldErrors,
            ...filedsErrors,
          }));

          const imapConnectionError = error.data.violations.find((violation) => violation.propertyPath === 'imapConnection');
          const smtpConnectionError = error.data.violations.find((violation) => violation.propertyPath === 'smtpConnection');

          setGeneralErrors((oldGeneralErrors) => ({
            ...oldGeneralErrors,
            imap: {
              ...oldGeneralErrors.imap,
              description: imapConnectionError?.message || null,
            },
            smtp: {
              ...oldGeneralErrors.smtp,
              description: smtpConnectionError?.message || null,
            },
          }));
        }
      },
    });
  }, [validateForm, createIMAPAccount, fromName, onAdded, onClose, fromEmail, serverData]);

  const onAutoDiscoverClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    sendAutoDiscover({
      emailAddress: fromEmail,
    }, {
      onSuccess: (data) => {
        if (data.fromEmail) {
          setFromEmail(data.fromEmail);
        }

        if (data.fromName) {
          setFromName(data.fromName);
        }

        setServerData((oldServerData) => ({
          ...oldServerData,
          imap: {
            ...oldServerData.imap,
            username: data.username,
            server: data.host,
            port: data.port,
            type: data.secureLevel,
            password,
          },
          smtp: {
            ...oldServerData.smtp,
            username: data.smtpUsername,
            server: data.smtpHost,
            port: data.smtpPort,
            type: data.smtpSecureLevel,
            password,
          },
        }));

        onSaveClick({
          fromEmail: data.fromEmail,
          fromName,
          username: fromEmail,
          password,
          host: data.host,
          port: data.port,
          secureLevel: data.secureLevel,
          smtpUsername: fromEmail,
          smtpPassword: password,
          smtpHost: data.smtpHost,
          smtpPort: data.smtpPort,
          smtpSecureLevel: data.smtpSecureLevel,
        });
      },
      onError: () => {
        setIsShowDetails(true);
      },
    });
  }, [validateForm, sendAutoDiscover, fromName, fromEmail, password, onSaveClick]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Email Account - IMAP / SMTP"
      className="add-imap-modal"
    >
      <div className="add-imap-modal-wrapper">
        {isEmailForGroupLoading && <CircularProgress className="loader" />}

        {!isEmailForGroupLoading && (
        <div>
          <div className="inputs">
            <Input
              label="From Name"
              value={fromName}
              onChange={(value) => onChangeInput({ value, setter: setFromName, errorField: 'fromName' })}
              error={!!errors.fromName}
              helperText={errors.fromName}
            />

            <Input
              label="Email Address"
              value={fromEmail}
              onChange={(value) => onChangeInput({ value, setter: setFromEmail, errorField: 'fromEmail' })}
              error={!!errors.fromEmail}
              helperText={errors.fromEmail}
            />

            {!isShowDetails && (
            <>
              <Input
                label="Password"
                value={password}
                onChange={(value) => onChangeInput({ value, setter: setPassword, errorField: 'password' })}
                type="password"
                error={!!errors.password}
                helperText={errors.password}
                isPasswordViewIconVisible={false}
              />

              <Input
                label="Confirm Password"
                value={confirmPassword}
                onChange={(value) => onChangeInput({ value, setter: setConfirmPassword, errorField: 'confirmPassword' })}
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                isPasswordViewIconVisible={false}
              />
            </>
            )}
          </div>

          {isShowDetails && (
          <>
            <div className="line" />

            <div className="inputs">
              {isShowDetails && generalErrors.imap.description && (
              <Alert
                severity="error"
                action={(
                  <div
                    className="more-error-info"
                    onClick={() => {
                      setGeneralErrors((oldGeneralErrors) => ({
                        ...oldGeneralErrors,
                        imap: {
                          ...oldGeneralErrors.imap,
                          expanded: !oldGeneralErrors.imap.expanded,
                        },
                      }));
                    }}
                    aria-hidden
                  >
                    <p>More Info</p>

                    {generalErrors.imap.expanded
                      ? <FontAwesomeIcon icon={faChevronUp} color="rgba(255, 255, 255, 0.70)" fontSize={13} />
                      : <FontAwesomeIcon icon={faChevronDown} color="rgba(255, 255, 255, 0.70)" fontSize={13} />}
                  </div>
              )}
              >
                <AlertTitle sx={{ marginBottom: generalErrors.imap.expanded ? '16px' : 0 }}>
                  {generalErrors.imap.title}
                </AlertTitle>

                {generalErrors.imap.expanded && <p style={{ maxWidth: 400 }}>{generalErrors.imap.description}</p>}
              </Alert>
              )}

              <Input
                label="IMAP Server"
                value={serverData.imap.server}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    imap: {
                      ...oldServerData.imap,
                      server: value,
                    },
                  }));

                  if (errors.imap.server) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      imap: {
                        ...oldErrors.imap,
                        server: null,
                      },
                    }));
                  }
                }}
                error={!!errors.imap.server}
                helperText={errors.imap.server}
              />

              <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
                <Input
                  label="Port"
                  value={serverData.imap.port}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      imap: {
                        ...oldServerData.imap,
                        port: value,
                      },
                    }));

                    if (errors.imap.port) {
                      setErrors((oldErrors) => ({
                        ...oldErrors,
                        imap: {
                          ...oldErrors.imap,
                          port: null,
                        },
                      }));
                    }
                  }}
                  error={!!errors.imap.port}
                  helperText={errors.imap.port}
                  style={{ width: 300 }}
                />

                {serverData.imap.type && (
                <Select
                  wrapperClassName="select-wrapper"
                  value={serverData.imap.type}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      imap: {
                        ...oldServerData.imap,
                        type: value,
                      },
                    }));
                  }}
                  items={PORT_TYPE_SELECT_ITEMS}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>SSL/TLS</p>
                      <p>
                        {PORT_TYPE_SELECT_ITEMS.find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />
                )}
              </div>

              <Input
                label="Username"
                value={serverData.imap.username}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    imap: {
                      ...oldServerData.imap,
                      username: value,
                    },
                  }));

                  if (errors.imap.username) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      imap: {
                        ...oldErrors.imap,
                        username: null,
                      },
                    }));
                  }
                }}
                error={!!errors.imap.username}
                helperText={errors.imap.username}
              />

              <Input
                label="Password"
                value={serverData.imap.password}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    imap: {
                      ...oldServerData.imap,
                      password: value,
                    },
                  }));

                  if (errors.imap.password) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      imap: {
                        ...oldErrors.imap,
                        password: null,
                      },
                    }));
                  }
                }}
                error={!!errors.imap.password}
                helperText={errors.imap.password}
                type="password"
              />
            </div>

            <div className="line" />

            <div className="inputs">
              {isShowDetails && generalErrors.smtp.description && (
              <Alert
                severity="error"
                action={(
                  <div
                    className="more-error-info"
                    onClick={() => {
                      setGeneralErrors((oldGeneralErrors) => ({
                        ...oldGeneralErrors,
                        smtp: {
                          ...oldGeneralErrors.smtp,
                          expanded: !oldGeneralErrors.smtp.expanded,
                        },
                      }));
                    }}
                    aria-hidden
                  >
                    <p>More Info</p>

                    {generalErrors.smtp.expanded
                      ? <FontAwesomeIcon icon={faChevronUp} color="rgba(255, 255, 255, 0.70)" fontSize={13} />
                      : <FontAwesomeIcon icon={faChevronDown} color="rgba(255, 255, 255, 0.70)" fontSize={13} />}
                  </div>
              )}
              >
                <AlertTitle sx={{ marginBottom: generalErrors.smtp.expanded ? '16px' : 0 }}>
                  {generalErrors.smtp.title}
                </AlertTitle>

                {generalErrors.smtp.expanded && <p style={{ maxWidth: 400 }}>{generalErrors.smtp.description}</p>}
              </Alert>
              )}

              <Input
                label="SMTP Server"
                value={serverData.smtp.server}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    smtp: {
                      ...oldServerData.smtp,
                      server: value,
                    },
                  }));

                  if (errors.smtp.server) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      smtp: {
                        ...oldErrors.smtp,
                        server: null,
                      },
                    }));
                  }
                }}
                error={!!errors.smtp.server}
                helperText={errors.smtp.server}
              />

              <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
                <Input
                  label="Port"
                  value={serverData.smtp.port}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      smtp: {
                        ...oldServerData.smtp,
                        port: value,
                      },
                    }));

                    if (errors.smtp.port) {
                      setErrors((oldErrors) => ({
                        ...oldErrors,
                        smtp: {
                          ...oldErrors.smtp,
                          port: null,
                        },
                      }));
                    }
                  }}
                  error={!!errors.smtp.port}
                  helperText={errors.smtp.port}
                  style={{ width: 300 }}
                />

                {serverData.smtp.type && (
                <Select
                  wrapperClassName="select-wrapper"
                  value={serverData.smtp.type}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      smtp: {
                        ...oldServerData.smtp,
                        type: value,
                      },
                    }));
                  }}
                  items={PORT_TYPE_SELECT_ITEMS}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>SSL/TLS</p>
                      <p>
                        {PORT_TYPE_SELECT_ITEMS.find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />
                )}
              </div>

              <Input
                label="Username"
                value={serverData.smtp.username}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    smtp: {
                      ...oldServerData.smtp,
                      username: value,
                    },
                  }));

                  if (errors.smtp.username) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      smtp: {
                        ...oldErrors.smtp,
                        username: null,
                      },
                    }));
                  }
                }}
                error={!!errors.smtp.username}
                helperText={errors.smtp.username}
              />

              <Input
                label="Password"
                value={serverData.smtp.password}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    smtp: {
                      ...oldServerData.smtp,
                      password: value,
                    },
                  }));

                  if (errors.smtp.password) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      smtp: {
                        ...oldErrors.smtp,
                        password: null,
                      },
                    }));
                  }
                }}
                error={!!errors.smtp.password}
                helperText={errors.smtp.password}
                type="password"
              />
            </div>
          </>
          )}

          <div className="confirm-buttons">
            <Button
              className="cancel-button"
              color="secondaryButton"
              onClick={onClose}
              disabled={isCreateIMAPAccountLoading}
            >
              Cancel
            </Button>
            {!isShowDetails ? (
              <>
                <Button
                  className="advanced-button"
                  color="secondaryButton"
                  onClick={() => setIsShowDetails(true)}
                  disabled={isAutoDiscoverLoading || isCreateIMAPAccountLoading}
                >
                  Advanced
                </Button>
                <Button
                  className="auto-discover-button"
                  onClick={onAutoDiscoverClick}
                  loading={isAutoDiscoverLoading || isCreateIMAPAccountLoading}
                  disabled={!fromName || !fromEmail || !password || !confirmPassword
                  || errors.fromName || errors.fromEmail || errors.password || errors.confirmPassword}
                >
                  Auto Discover
                </Button>
              </>
            ) : (
              <Button
                className="save-button"
                onClick={() => onSaveClick()}
                loading={isCreateIMAPAccountLoading}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        )}
      </div>
    </Modal>
  );
};

AddIMAPModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default React.memo(AddIMAPModal);
