import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import AuthorizedLayout from 'layouts/authorized';

import { PlusIcon, LetterWithAtIcon } from 'assets/icons';

import { connectivityApi } from 'resources/connectivity';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import { Checkbox, CircularProgress, FormControlLabel, Menu, MenuItem, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter, faXmark } from '@fortawesome/pro-regular-svg-icons';

import AddEmailAccountModal from './components/AddEmailAccountModal';
import Item from './components/Item';

import './styles.scss';

const PER_PAGE = 10;

const EmailAccounts = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);
  const [filters, setFilters] = useState({
    active: false,
    pending: false,
    created: false,
    disconnected: false,
    archived: false,
  });

  const selectedFilters = Object.keys(filters).filter((filter) => filters[filter]);

  const [isAddEmailAccountModalVisible, setIsAddEmailAccountModalVisible] = useState(false);
  const { data, isLoading } = connectivityApi.useConnectivityEmailAccounts({
    page,
    perPage: PER_PAGE,
    accountStatus: selectedFilters.length ? selectedFilters : undefined,
  });
  const [fetchedItems, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  useEffect(() => {
    if (fetchedItems) {
      setItems(fetchedItems);
    }
  }, [fetchedItems]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / PER_PAGE);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClearFilters = useCallback((e) => {
    e.stopPropagation();

    setFilters({
      active: false,
      pending: false,
      created: false,
      disconnected: false,
      archived: false,
    });
  }, []);

  const selectedFiltersLength = Object.keys(filters).filter((filter) => filters[filter]).length;

  const EmptyState = () => (
    <div className="empty-state">
      <LetterWithAtIcon />

      <p className="empty-state-title">Connect your email account</p>

      <Button
        startIcon={<PlusIcon />}
        iconClassName="button-icon"
        onClick={() => setIsAddEmailAccountModalVisible(true)}
      >
        Connect Account
      </Button>
    </div>
  );

  return (
    <AuthorizedLayout>
      {isAddEmailAccountModalVisible && (
      <AddEmailAccountModal
        open={isAddEmailAccountModalVisible}
        onClose={() => setIsAddEmailAccountModalVisible(false)}
      />
      )}

      <div className="email-accounts-wrapper">
        <div>
          <div className="title">
            <div>
              <p>Email Accounts</p>

              <div
                className={cn('filter-container', !!anchorEl && 'filter-container-active')}
                onClick={openMenu}
                aria-hidden
              >
                <FontAwesomeIcon icon={faBarsFilter} fontSize={14} />

                <p>
                  Filter
                  {selectedFiltersLength ? ` (${selectedFiltersLength})` : null}
                </p>

                {!!selectedFiltersLength && (
                <Tooltip title="Clear filter" placement="top">
                  <FontAwesomeIcon onClick={onClearFilters} icon={faXmark} fontSize={15} className="clear-filter-icon" />
                </Tooltip>
                )}
              </div>

              <Menu
                open={!!anchorEl}
                onClose={closeMenu}
                anchorEl={anchorEl}
                style={{
                  marginTop: 3,
                }}
              >
                {Object.keys(filters).map((filter) => (
                  <MenuItem
                    onClick={() => null}
                    style={{
                      textTransform: 'capitalize',
                      padding: 0,
                    }}
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={filters[filter]}
                          onChange={(event) => {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              [filter]: event.target.checked,
                            }));
                          }}
                        />
                      )}
                      label={filter}
                      style={{
                        width: '100%',
                        paddingLeft: 10,
                        paddingRight: 30,
                      }}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </div>

            {!isLoading && !!items.length && (
            <Button
              onClick={() => setIsAddEmailAccountModalVisible(true)}
            >
              Connect Account
            </Button>
            )}
          </div>
        </div>

        {!isLoading && !items.length && <EmptyState />}

        {!isLoading && !!items.length && (
        <div className="list">
          {isLoading && <CircularProgress className="table-loader" />}

          <div className="table-head">
            <p className="email-address-head">Email Address</p>
            <p className="from-name-head">From Name</p>
            <p className="email-provider-head">Email Provider</p>
            <p className="mx-score-head">MX Score</p>
            <p className="status-head">Status</p>
          </div>

          <div className="table-head-line" />

          <div>
            {items.map((item) => <Item key={JSON.stringify(item)} item={item} />)}
          </div>

          {totalItems > items.length && (
          <Pagination
            className="table-pagination"
            activePage={page}
            perPage={PER_PAGE}
            totalItems={totalItems}
            onPageChanged={onPageChanged}
          />
          )}
        </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default EmailAccounts;
