import React from 'react';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';
import cn from 'classnames';

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { DotsIcon } from 'assets/icons';

import './styles.scss';

const getGroupQuality = (quality) => {
  if (quality >= 95) {
    return {
      label: 'Excellent',
      color: 'success',
    };
  }

  if (quality >= 75) {
    return {
      label: 'Good',
      color: 'success',
    };
  }

  if (quality > 50) {
    return {
      label: 'Fair',
      color: 'warning',
    };
  }

  return {
    label: 'Poor',
    color: 'error',
  };
};

const Speedometer = ({ serviceGroup, passTestsCount, totalTestsCount }) => {
  const updatedServiceGroupName = ['smtp', 'dns'].includes(serviceGroup) ? serviceGroup.toUpperCase() : serviceGroup;

  const { label, color } = getGroupQuality(totalTestsCount > 0
    ? (passTestsCount / totalTestsCount) * 100
    : 0);

  const isResultAvaliable = passTestsCount && totalTestsCount && label;

  return (
    <div className="speedometer">
      <div>
        <div className="name">
          <p>{updatedServiceGroupName}</p>

          <IconButton><DotsIcon /></IconButton>
        </div>

        {isResultAvaliable ? (
          <GaugeChart
            className={cn('guage')}
            id={`gauge-${serviceGroup}`}
            nrOfLevels={30}
            colors={['rgba(0, 145, 255, 1)', 'rgba(23, 237, 195, 1)']}
            percent={passTestsCount / totalTestsCount}
            hideText
          />
        )
          : <CircularProgress size={107} color="neutral" className="loader" />}
      </div>

      <div className="test-results">
        <p>TEST PASS: {passTestsCount || 2}/{totalTestsCount || 2}</p>

        {isResultAvaliable
          ? <p className={cn('label', `label-${color}`)}>{label}</p>
          : <div className="blur" />}
      </div>
    </div>
  );
};

Speedometer.propTypes = {
  serviceGroup: PropTypes.string.isRequired,
  passTestsCount: PropTypes.number,
  totalTestsCount: PropTypes.number,
};

Speedometer.defaultProps = {
  passTestsCount: null,
  totalTestsCount: null,
};

export default React.memo(Speedometer);
