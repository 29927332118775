import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { DotsIcon } from 'assets/icons';

import { Menu, MenuItem, Chip, IconButton, Tooltip, CircularProgress } from '@mui/material';

import { connectivityApi } from 'resources/connectivity';

import DeleteModal from 'components/DeleteModal';
import ScoreCircle from 'components/ScoreCircle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faPen, faRotateRight, faSunrise, faTrashCan } from '@fortawesome/pro-regular-svg-icons';

import { getUserInitials } from 'pages/Profile';

// eslint-disable-next-line max-len
import AddOrEditEmailInfrastructureMonitorModal from 'pages/EmailInfrastructure/components/AddOrEditEmailInfrastructureMonitorModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import EditIMAPModal from './components/EditIMAPModal';
import EditEmailProviderModal from './components/EditEmailProviderModal';

import './styles.scss';

const getChipLabelByStatus = (status) => {
  if (status === 'active') {
    return {
      label: 'Active',
      value: 'success',
    };
  }

  if (status === 'disconnected') {
    return {
      label: 'Disconnected',
      value: 'warning',
    };
  }

  if (status === 'archived') {
    return {
      label: 'Archived',
      value: 'default',
    };
  }

  if (status === 'failed') {
    return {
      label: 'Failed',
      value: 'error',
    };
  }

  return {
    label: status ? `${status[0].toUpperCase()}${status.slice(1)}` : 'Unknown',
    value: 'default',
  };
};

const Item = ({ item }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddEmailMonitorModalVisible, setIsAddEmailMonitorModalVisible] = useState(false);
  const [isEditIMAPModalVisible, setIsEditIMAPModalVisible] = useState(false);
  const [isEditEmailProviderModalVisible, setIsEditEmailProviderModalVisible] = useState(false);

  const {
    mutate: updateEmailConnectivityAccountStatus,
    isLoading: isUpdateEmailConnectivityAccountStatusLoading,
  } = connectivityApi.useUpdateConnectivityEmailAccountStatus();
  const {
    mutate: deleteEmailConnectivityAccount,
    isLoading: isDeleteEmailConnectivityAccountLoading,
  } = connectivityApi.useDeleteConnectivityEmailAccount();
  const {
    mutate: reconnectConnectivityEmailAccount,
    isLoading: isReconnectConnectivityEmailAccountLoading,
  } = connectivityApi.useReconnectConnectivityEmailAccount();
  const {
    mutate: getGoogleAuthUrl,
  } = connectivityApi.useGetConnectivityGoogleAuthUrl();
  const {
    mutate: getMSAuthUrl,
  } = connectivityApi.useGetConnectivityMSAuthUrl();

  const chipData = getChipLabelByStatus(item.accountStatus);
  const isArchived = item.accountStatus === 'archived';
  const isAddMonitor = item.accountStatus === 'active' && !item.monitor;
  const isMonitorActive = item.monitor && item.monitor.lastRunAt;

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onArchiveToggle = useCallback(() => {
    updateEmailConnectivityAccountStatus({
      id: item.id,
      status: isArchived ? 'active' : 'archived',
    }, {
      onSuccess: () => {
        closeMenu();
      },
    });
  }, [updateEmailConnectivityAccountStatus, closeMenu, item.id, isArchived]);

  const onDelete = useCallback(() => {
    deleteEmailConnectivityAccount(item.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email account has been deleted');

        closeMenu();
      },
    });
  }, [deleteEmailConnectivityAccount, closeMenu, item.id]);

  const onEditClick = useCallback(() => {
    if (item.clientType === 'imap') {
      setIsEditIMAPModalVisible(true);

      return;
    }

    setIsEditEmailProviderModalVisible(true);
  }, [item]);

  const onReconnectClick = useCallback(() => {
    closeMenu();

    if (item.clientType === 'imap') {
      reconnectConnectivityEmailAccount(item.id, {
        onSuccess: () => {
          uiNotificationService.showSuccess('Email account has been reconnected');
        },
      });

      return;
    }

    const onSuccess = (data) => {
      window.open(data.authUrl, '_self');
    };

    if (item.clientType === 'gmail') {
      getGoogleAuthUrl({}, {
        onSuccess,
      });

      return;
    }

    if (item.clientType === 'o365') {
      getMSAuthUrl({}, {
        onSuccess,
      });
    }
  }, [item.id, item.clientType, reconnectConnectivityEmailAccount,
    closeMenu, getGoogleAuthUrl, getMSAuthUrl]);

  return (
    <div
      className={cn('email-account-row-container', isArchived && 'email-account-row-container-archived')}
    >
      {isDeleteModalVisible && (
      <DeleteModal
        title={`Are you sure you want to delete email account ${item.fromEmail || item.username}?`}
        open={isDeleteModalVisible}
        onClose={(e) => { e.stopPropagation(); setIsDeleteModalVisible(false); closeMenu(); }}
        onDelete={(e) => { e.stopPropagation(); onDelete(); }}
        isDeleteLoading={isDeleteEmailConnectivityAccountLoading}
      />
      )}
      {isAddEmailMonitorModalVisible && (
      <AddOrEditEmailInfrastructureMonitorModal
        onClose={() => setIsAddEmailMonitorModalVisible(false)}
        emailAccountId={item.id}
      />
      )}
      {isEditIMAPModalVisible && (
      <EditIMAPModal
        open={isEditIMAPModalVisible}
        onClose={() => { setIsEditIMAPModalVisible(false); closeMenu(); }}
        item={item}
      />
      )}
      {isEditEmailProviderModalVisible && (
      <EditEmailProviderModal
        open={isEditEmailProviderModalVisible}
        onClose={() => { setIsEditEmailProviderModalVisible(false); closeMenu(); }}
        item={item}
      />
      )}

      <div className="email-item-row">
        <div className="email">
          <div className="avatar-placeholder">
            {getUserInitials({
              firstName: item.fromName ? item.fromName.split(' ')[0] : null,
              lastName: item.fromName ? item.fromName.split(' ')[1] : null,
              email: item.fromEmail || item.username,
            })}
          </div>
          <p>{item.fromEmail || item.username}</p>
        </div>

        <div className="from-name">
          <p>{item.fromName}</p>
        </div>

        <div className="email-provider">
          <p>{item.provider}</p>
        </div>

        <div className="mx-score">
          {isAddMonitor && (
            <div
              className="mx-score-add"
              onClick={() => setIsAddEmailMonitorModalVisible(true)}
              aria-hidden
            >
              <ScoreCircle
                mxrScoreTotal={10}
                mxrScoreTotalPass={9}
                ratingColor="#0091FF"
                ratingLabel="+"
                onlyCircle
                size="small"
              />

              <p>Add Monitor</p>
            </div>
          )}

          {item.monitor && (
            <Tooltip
              title={item.monitor.status === 'disabled'
                ? 'Email Monitor is disabled. The score may be outdated.'
                : ''}
            >
              <div
                onClick={() => {
                  if (!item.lastMonitorRun?.toEmail) {
                    return;
                  }

                  const monitorId = +item.monitor['@id'].replace('/v2/monitors/', '');

                  navigate(routes.monitorResult.path
                    .replace(':monitorId', monitorId)
                    .replace(':email', item.lastMonitorRun.toEmail));
                }}
                aria-hidden
              >
                <ScoreCircle
                  mxrScoreTotal={isMonitorActive ? 10 : undefined}
                  mxrScoreTotalPass={item.monitor.mxrScore}
                  ratingColor={item.monitor.ratingColor}
                  ratingLabel={item.monitor.ratingLabel || 'U'}
                  onlyCircle
                  size="small"
                  waiting={!isMonitorActive}
                  disabled={item.monitor.status === 'disabled'}
                />
              </div>
            </Tooltip>
          )}
        </div>

        <div className="status">
          {isReconnectConnectivityEmailAccountLoading
            ? <CircularProgress size={26} />
            : (
              <div className="chip-container">
                <Chip
                  label={chipData.label}
                  color={chipData.value}
                  variant="outlined"
                  className={cn(isArchived && 'chip-archived')}
                />
              </div>
            )}
        </div>

        <div className={cn('menu-wrapper', 'email-account-menu-wrapper', anchorEl && 'menu-wrapper-active')}>
          <IconButton onClick={openMenu}>
            <DotsIcon />
          </IconButton>

          <Menu
            open={!!anchorEl}
            onClose={closeMenu}
            anchorEl={anchorEl}
          >
            {item.accountStatus === 'disconnected' && (
            <MenuItem onClick={onReconnectClick} className="menu-item">
              <FontAwesomeIcon icon={faRotateRight} color="rgba(255, 255, 255, 0.56)" />
              <p>Reconnect</p>
            </MenuItem>
            )}
            <MenuItem onClick={onEditClick} className="menu-item">
              <FontAwesomeIcon icon={faPen} color="rgba(255, 255, 255, 0.56)" />
              <p>Edit</p>
            </MenuItem>
            <MenuItem
              onClick={onArchiveToggle}
              className="menu-item"
              disabled={isUpdateEmailConnectivityAccountStatusLoading}
            >
              <FontAwesomeIcon icon={isArchived ? faSunrise : faBoxArchive} color="rgba(255, 255, 255, 0.56)" />
              <p>{isArchived ? 'Restore' : 'Archive'}</p>
            </MenuItem>
            <MenuItem onClick={() => setIsDeleteModalVisible(true)} className="menu-item" disabled={false}>
              <FontAwesomeIcon icon={faTrashCan} color="rgba(255, 255, 255, 0.56)" />
              <p>Delete</p>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className="line" />
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default Item;
